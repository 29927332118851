<template>
  <div class="home" id="home">
    <content1 />
    <content2 />
    <content3 />
    <content4 />
    <content5 />
    <content6 />
  </div>
</template>
<script>
export default {
  name: 'home',
  components: {
    'content1':() => import(('./Content1.vue')),
    'content2':() => import(('./Content2.vue')),
    'content3':() => import(('./Content3.vue')),
    'content4':() => import(('./Content4.vue')),
    'content5':() => import(('./Content5.vue')),
    'content6':() => import(('./Content6.vue'))
  },
  mounted () {
    var VueScrollTo = require('vue-scrollto');
    var element = location.hash

    if (location.hash) {
      this.$nextTick(() => {
        setTimeout(function() {
          VueScrollTo.scrollTo(element)
        }, 100)
      })
    }
  }
}
</script>

<style scoped lang='scss'></style>
